<template>
    <b-modal
      v-model="data.showModal"
      size="md"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          <span v-if="item.product">
            {{ item.product }}
          </span>
          <span v-else>
            Product
          </span>
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <table class="table table-google">
          <thead>
            <tr>
              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-nowrap">Cost Type</td>
              <td>
                <button-select
                  v-model="item.cost_type"
                  :options="options.cost_type"
                  size="sm"
                  class="w-50"
                  @input="onCostType"
                />
              </td>
            </tr>

            <tr>
              <td class="text-nowrap">Product</td>
              <td>
                <b-form-input
                  v-model="item.product"
                  size="sm"
                  placeholder="Product"
                  autofocus
                  autocomplete="off"
                ></b-form-input>
              </td>
            </tr>

            <tr>
              <td class="text-nowrap">Purchase Price</td>
              <td>
                <div class="flex gap-2">
                  <b-form-input
                    v-model="item.purchase_price"
                    size="sm"
                    placeholder="Purchase Price"
                    autocomplete="off"
                  ></b-form-input>

                  <b-form-select
                    v-model="item.purchase_currency"
                    :options="options.purchase_currency"
                    size="sm"
                    class="w-50"
                  />
                </div>
              </td>
            </tr>

            <tr v-if="item.cost_type === 'variable'">
              <td class="text-nowrap">Unit Type</td>
              <td>
                <button-select
                  v-model="item.product_unit_type"
                  :options="options.product_unit_type"
                  size="sm"
                  class="w-50"
                  @input="onUnitType"
                />
              </td>
            </tr>

            <tr v-if="item.cost_type === 'variable'">
              <td class="text-nowrap">Purchase Quantity</td>
              <td>
                <div class="flex gap-2">
                  <b-form-input
                    v-model="item.purchase_unit_quantity"
                    size="sm"
                    placeholder="Purchase Quantity"
                    autocomplete="off"
                  ></b-form-input>

                  <b-form-select
                    v-model="item.purchase_unit"
                    :options="productUnits"
                    size="sm"
                    class="w-50"
                  />
                </div>
              </td>
            </tr>

            <tr v-if="item.cost_type === 'variable'">
              <td class="text-nowrap">Sale Quantity</td>
              <td>
                <div class="flex gap-2">
                  <b-form-input
                    v-model="item.sale_unit_quantity"
                    size="sm"
                    placeholder="Sale Quantity"
                    autocomplete="off"
                  ></b-form-input>

                  <b-form-select
                    v-model="item.sale_unit"
                    :options="productUnits"
                    size="sm"
                    class="w-50"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div class="flex gap-2">
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canSave"
              @click="onSave"
            >
              Add
            </b-btn>
          </div>
          <div class="flex gap-2">
            <b-btn
              size="sm"
              variant="danger"
              :disabled="isLoading"
              @click="deleteItem()"
            >
              Delete
            </b-btn>

            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { stringToNumber, numberToString } from '@/helpers';

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');

export default {
  name: 'ProductEditor',
  components: {
    ButtonSelect,
  },
  props: {
    data: Object,
    units: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      return true;
    },
    productUnits() {
      return Object.keys(this.units[this.item.product_unit_type])
        .map((unit) => ({ text: unit, value: unit }));
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {},
      options: {
        cost_type: [
          { text: 'Variable', value: 'variable' },
          { text: 'Fixed', value: 'fixed' },
        ],
        product_unit_type: [
          { text: 'Amount', value: 'amount' },
          { text: 'Length', value: 'length' },
          { text: 'Weight', value: 'weight' },
        ],
        purchase_currency: [],
      },
      item: {
        id: null,
        uuid: uuidv4(),
        cost_type: 'variable',
        product: '',
        product_unit_type: 'length',
        purchase_currency: 'CZK',
        purchase_unit: 'm',
        purchase_unit_quantity: '',
        purchase_price: '',
        sale_unit: 'm',
        sale_unit_quantity: '',
      },
    };
  },
  methods: {
    onCostType(costType) {
      if (costType === 'fixed') {
        this.item.product_unit_type = 'amount';
        this.item.purchase_unit = 'amount';
        this.item.sale_unit = 'amount';
        this.item.purchase_unit_quantity = '1';
        this.item.sale_unit_quantity = '1';
      }
    },
    onUnitType(unitType) {
      const defaultUnit = Object.entries(this.units[unitType]).find((unit) => unit[1] === 1)[0];
      this.item.purchase_unit = defaultUnit;
      this.item.sale_unit = defaultUnit;
    },
    prepareCurrency() {
      this.options.purchase_currency = Object.keys(this.units.currency)
        .map((currency) => ({ text: currency, value: currency }));
    },
    loadItem() {
      if (this.data.cost_type) {
        this.item.cost_type = this.data.cost_type;
        this.onCostType(this.data.cost_type);
      }

      if (this.data.item === null) {
        return;
      }
      const { item } = this.data;

      this.item.id = item.id;
      this.item.uuid = item.uuid;
      this.item.cost_type = item.cost_type;
      this.item.product = item.product;
      this.item.product_unit_type = item.product_unit_type;
      this.item.purchase_currency = item.purchase_currency;
      this.item.purchase_unit = item.purchase_unit;
      this.item.sale_unit = item.sale_unit;
      this.item.purchase_unit_quantity = numberToString(item.purchase_unit_quantity);
      this.item.purchase_price = numberToString(item.purchase_price);
      this.item.sale_unit_quantity = numberToString(item.sale_unit_quantity);
    },
    saveItem() {
      const saveItem = {
        id: this.item.id,
        uuid: this.item.uuid,
        cost_type: this.item.cost_type,
        product: this.item.product,
        product_unit_type: this.item.product_unit_type,
        purchase_currency: this.item.purchase_currency,
        purchase_unit: this.item.purchase_unit,
        sale_unit: this.item.sale_unit,
        purchase_unit_quantity: stringToNumber(this.item.purchase_unit_quantity),
        purchase_price: stringToNumber(this.item.purchase_price),
        sale_unit_quantity: stringToNumber(this.item.sale_unit_quantity),
      };
      this.$emit('save', saveItem);
      this.onClose();
    },
    deleteItem() {
      if (!confirm(`Do you really wish to delete '${this.item.product}'?`)) {
        return;
      }
      this.$emit('delete', this.item);
      this.onClose();
    },
    fetchAll() {
      // Implement logic
    },
    onSave() {
      this.saveItem();
    },
    onShow() {
      this.prepareCurrency();
      this.loadItem();
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
